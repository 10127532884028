import React from "react"
import Container from "../components/container"
import Text from "../components/text"
import Title from "../components/title"
import Social from "../components/social"
import Nav from "../components/nav"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useSpring } from "react-spring"
import { Spring } from "react-spring/renderprops"
import { MenuIcon } from "../components/menu"
import GlobalStyles from './styles/globalStyles'
import "./styles/index.css"

export default ({ data }) => {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } })
  const [navStatus, displayNav] = React.useState(false)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tom Large | Developer | Uses</title>
        <link rel="canonical" href="https://tomlarge.dev/uses" />
      </Helmet>
      <GlobalStyles />
      <Container>
        {navStatus && (
          <Spring
            from={{ transform: `translate3d(-100%, 0, 0)` }}
            to={{ transform: `translate3d(0, 0, 0)` }}
          >
            {props => <Nav style={props} />}
          </Spring>
        )}
        <MenuIcon onClick={() => displayNav(!navStatus)} open={navStatus} />
        <Title style={props}>Uses <span role="img" aria-label="laptop">💻</span></Title>
        <Title style={props} fontSizeSmall="22px" fontSizeLarge="52px">
          Hardware
        </Title>
        <Text style={props}>
          <ul>
            <li>MacBook Pro (15-inch, 2019) & MacBook Pro (15-inch, 2014)</li>
            <li>
              2x Dell 27inch montiors
            </li>
            <li>
              Laptop stand - Lamicall Portable Laptop Riser : Adjustable
              Foldable Ergonomic
            </li>
            <li>
              Keyboard and Mouse - Apple magic mouse 2 & Apple Magic Keyboard
              with Numeric Keypad
            </li>
            <li>Misc - USB C to USB 3.0 Adapters</li>
          </ul>
        </Text>
        <Title style={props} fontSizeSmall="22px" fontSizeLarge="52px">
          Software
        </Title>
        <Text style={props}>
          I use the following tools for web development and writing:
          <ul>
            <li>Chrome</li>
            <li>Docker</li>
            <li>Valet</li>
            <li>iTerm3 with ZSH / Oh My ZSH</li>
            <li>Alfred 4</li>
            <li>Android Studio</li>
            <li>Xcode</li>
            <li>CleanMyMac X</li>
            <li>Gifox</li>
            <li>Pusher</li>
            <li>TablePlus</li>
            <li>Visual Studio Code</li>
            <li>PAW</li>
            <li>Notion</li>
            <li>Spectacle</li>
            <li>Slack</li>
            <li>Spotify</li>
            <li>Affinity Designer</li>
            <li>Affinity Photo</li>
          </ul>
        </Text>
        <Social />
      </Container>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        subtitle
        greeting
      }
    }
  }
`
